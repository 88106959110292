import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PromoView',
  computed: {
    ...mapGetters({
      segment: 'Kind16Check/segment',
      withVinPercent: 'others/withVinPercent',
    }),
  },
  methods: {
    ...mapActions({
      setSegment: 'Kind16Check/loadSegment',
      setWithVinPercent: 'others/withVinPercent',
    }),
    logger(actionBlock, clickAction) {
      this._slonik({
        event_id: 1342,
        action_block: actionBlock,
        click_action: clickAction,
        previous_url: document.referrer,
        request_type: clickAction && actionBlock ? 0 : 1,
      });
    },
  },
  mounted() {
    this.setSegment();
    this.setWithVinPercent();
    this.logger();
  },
  metaInfo() {
    return {
      title: `AUTO.RIA – ${this.$t('Як потрапити в рубрику «ТОП» на Авторіа?')}`,
      meta: [
        { name: 'description', content: this.$t('Додайте ваше оголошення в рубрику «ТОП» і воно буде розміщене на головній сторінці сайту як краща пропозиція! Оголошення на головній сторінці сайту переглядає величезна кількість покупців.') },
        { property: 'og:site_name', content: 'AUTO.RIA' },
        { property: 'og:title', content: `AUTO.RIA – ${this.$t('Як потрапити в рубрику «ТОП» на Авторіа?')}` },
        { property: 'og:description', content: this.$t('Додайте ваше оголошення в рубрику «ТОП» і воно буде розміщене на головній сторінці сайту як краща пропозиція! Оголошення на головній сторінці сайту переглядає величезна кількість покупців.') },
        { property: 'og:image', content: 'https://css.riastatic.com/images/og-placeholder.png' },
        { name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW' },
      ],
      link: [
        { rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/help/promo-view/' },
        { rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/help/promo-view/' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/help/promo-view/' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/help/promo-view/' },
        { rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/help/promo-view/' },
        { rel: 'canonical', href: `https://auto.ria.com${this.langPrefix || ''}/help/promo-view/` },
      ],
    };
  },
  i18n: {
    messages: {
      ru: {
        Як: 'Как',
        'допоможе продати': 'поможет продать',
        'Вкажіть VIN-код': 'Укажите VIN-код',
        'Це підвищує ефективність оголошення і це абсолютно безпечно — AUTO.RIA приховує 5 із 17 символів коду.': 'Это повышает эффективность объявления и это совершенно безопасно — AUTO.RIA скрывает 5 из 17 символов кода.',
        'продавців вказують VIN і продають швидше.': 'продавцов указывают VIN и продают быстрее.',
        Вказати: 'Указать',
        Користуйтесь: 'Пользуйтесь',
        'рівнями ТОП': 'уровнями ТОП',
        'Висока позиція авто в пошуку на ваш вибір': 'Высокая позиция авто в поиске на ваш выбор',
        'Додаткова увага покупців': 'Дополнительное внимание покупателей',
        'Швидкий та вигідний продаж': 'Быстрая и выгодная продажа',
        'Дізнатись більше': 'Узнать больше',
        'Що бачить покупець': 'Что видит покупатель',
        'Що знає продавець': 'Что знает продавец',
        'Перевірте ваше авто на': 'Проверьте ваше авто на',
        Технічно: 'Технически',
        'Гарантуйте відсутність несправностей перед продажем': 'Гарантируйте отсутствие неисправностей перед продажей.',
        Юридично: 'Юридически',
        'Продемонструйте достовірність документів та номерів агрегатів': 'Продемонстрируйте достоверность документов и номеров агрегатов.',
        'Підтвердіть відсутність угону, кредитів та інших обтяжень з розширеною перевіркою': 'Подтвердите отсутствие угона, кредитов и других обременений с расширенной проверкой VINa.\n',
        'Детальніше про перевірки': 'Подробнее о проверках',
        'Перевірити авто': 'Проверить авто',
        'Перевірити моє авто': 'Проверить моё авто',
        'Зробіть пропозицію інформативною': 'Сделайте предложение информативным',
        'Повністю заповніть анкету автомобіля': 'Полностью заполните анкету автомобиля',
        'Додайте правдивий опис про технічний стан авто та поділіться власним досвідом його експлуатації': 'Добавьте правдивое описание о техническом состоянии авто и поделитесь собственным опытом его эксплуатации.',
        'Завантажте кілька фото з різних ракурсів та відео, що продемонструє всі переваги авто': 'Загрузите несколько фото с разных ракурсов и видео, которое демонстрирует все преимущества авто.',
        'Редагувати мої авто': 'Редактировать мои авто',
        'Перейти до моїх авто': 'Перейти к моим авто',
        'Підняти мої оголошення': 'Поднять мои объявления',
        'Продати авто': 'Продать авто',
        'Розкажіть друзям та знайомим': 'Расскажите друзьям и знакомым',
        'Поділіться посиланням на своє авто у соцмережах': 'Поделитесь ссылкой на свое авто в соцсетях',
        'іноді покупець ближче, ніж здається.': 'иногда покупатель ближе, чем кажется.',
        Сервіси: 'Сервисы',
        'для росту ваших продажів': 'для роста ваших продаж',
        'На перших місцях у пошуку тільки авто з VIN-кодом. І це — найлегший спосіб отримати увагу покупців, їх перегляди та дзвінки без додаткових вкладень та зусиль': 'На первых местах в поиске только авто с VIN-кодом. И это — самый легкий способ получить внимание покупателей, их просмотры и звонки без дополнительных вложений и усилий',
        'Детальніше про VIN-код': 'Подробнее о VIN-кодe',
        'Промо-пакети для вигідного підняття': 'Промо-пакеты для выгодного поднятия',
        'в пошуку': 'в поиске',
        'Вигідне підняття на вищі позиції, де ваші авто отримають більше уваги покупців, а ви — ріст продажів. Бонуси до 170 грн в кожному пакеті': 'Выгодное поднятие на более высокие позиции, где ваши авто получат больше внимания покупателей, а вы — рост продаж. Бонусы до 170 грн в каждом пакете',
        'Приділіть увагу якості контенту': 'Уделите внимание качеству контента',
        'Максимально повністю заповнюйте анкети автомобілів': 'Максимально полностью заполняйте анкеты автомобилей',
        'Додавайте правдивий опис про технічний стан авто та, за можливості, діліться досвідом його експлуатації': 'Добавляйте правдивое описание о техническом состоянии авто и, по возможности, делитесь опытом его эксплуатации',
        'Завантажуйте фото з різних ракурсів та відео, які демонструють усі переваги авто': 'Загружайте фото с разных ракурсов и видео, которые демонстрируют все преимущества авто',
        'Як потрапити в рубрику «ТОП» на Авторіа?': 'Как попасть в рубрику «ТОП» на Авториа?',
        'Додайте ваше оголошення в рубрику «ТОП» і воно буде розміщене на головній сторінці сайту як краща пропозиція! Оголошення на головній сторінці сайту переглядає величезна кількість покупців.': 'Добавьте ваше объявление в рубрику «ТОП» и оно будет размещено на главной странице сайта как лучшее предложение! Объявления на главной странице сайта просматривает огромное число покупателей.',
        'Авто з VIN-кодом - вище в пошуку': 'Авто с VIN-кодом — выше в поиске',
        'Дізнатися більше про рівні ТОП': 'Узнать больше про уровни ТОП',

      },
      uk: {
        Як: 'Як',
        'допоможе продати': 'допоможе продати',
        'Вкажіть VIN-код': 'Вкажіть VIN-код',
        'Це підвищує ефективність оголошення і це абсолютно безпечно — AUTO.RIA приховує 5 із 17 символів коду.': 'Це підвищує ефективність оголошення і це абсолютно безпечно — AUTO.RIA приховує 5 із 17 символів коду.',
        'продавців вказують VIN і продають швидше.': 'продавців вказують VIN і продають швидше.',
        Вказати: 'Вказати',
        Користуйтесь: 'Користуйтесь',
        'рівнями ТОП': 'рівнями ТОП',
        'Висока позиція авто в пошуку на ваш вибір': 'Висока позиція авто в пошуку на ваш вибір',
        'Додаткова увага покупців': 'Додаткова увага покупців',
        'Швидкий та вигідний продаж': 'Швидкий та вигідний продаж',
        'Дізнатись більше': 'Дізнатись більше',
        'Що бачить покупець': 'Що бачить покупець',
        'Що знає продавець': 'Що знає продавець',
        'Перевірте ваше авто на': 'Перевірте ваше авто на',
        Технічно: 'Технічно',
        'Гарантуйте відсутність несправностей перед продажем': 'Гарантуйте відсутність несправностей перед продажем',
        Юридично: 'Юридично',
        'Продемонструйте достовірність документів та номерів агрегатів': 'Продемонструйте достовірність документів та номерів агрегатів',
        'Підтвердіть відсутність угону, кредитів та інших обтяжень з розширеною перевіркою': 'Підтвердіть відсутність угону, кредитів та інших обтяжень з розширеною перевіркою',
        'Детальніше про перевірки': 'Детальніше про перевірки',
        'Перевірити авто': 'Перевірити авто',
        'Перевірити моє авто': 'Перевірити моє авто',
        'Зробіть пропозицію інформативною': 'Зробіть пропозицію інформативною',
        'Повністю заповніть анкету автомобіля': 'Повністю заповніть анкету автомобіля',
        'Додайте правдивий опис про технічний стан авто та поділіться власним досвідом його експлуатації': 'Додайте правдивий опис про технічний стан авто та поділіться власним досвідом його експлуатації',
        'Завантажте кілька фото з різних ракурсів та відео, що продемонструє всі переваги авто': 'Завантажте кілька фото з різних ракурсів та відео, що продемонструє всі переваги авто',
        'Редагувати мої авто': 'Редагувати мої авто',
        'Перейти до моїх авто': 'Перейти до моїх авто',
        'Підняти мої оголошення': 'Підняти мої оголошення',
        'Продати авто': 'Продати авто',
        'Розкажіть друзям та знайомим': 'Розкажіть друзям та знайомим',
        'Поділіться посиланням на своє авто у соцмережах': 'Поділіться посиланням на своє авто у соцмережах',
        'іноді покупець ближче, ніж здається.': 'іноді покупець ближче, ніж здається.',
        Сервіси: 'Сервіси',
        'для росту ваших продажів': 'для росту ваших продажів',
        'На перших місцях у пошуку тільки авто з VIN-кодом. І це — найлегший спосіб отримати увагу покупців, їх перегляди та дзвінки без додаткових вкладень та зусиль': 'На перших місцях у пошуку тільки авто з VIN-кодом. І це — найлегший спосіб отримати увагу покупців, їх перегляди та дзвінки без додаткових вкладень та зусиль',
        'Детальніше про VIN-код': 'Детальніше про VIN-код',
        'Промо-пакети для вигідного підняття': 'Промо-пакети для вигідного підняття',
        'в пошуку': 'в пошуку',
        'Вигідне підняття на вищі позиції, де ваші авто отримають більше уваги покупців, а ви — ріст продажів. Бонуси до 170 грн в кожному пакеті': 'Вигідне підняття на вищі позиції, де ваші авто отримають більше уваги покупців, а ви — ріст продажів. Бонуси до 170 грн в кожному пакеті',
        'Приділіть увагу якості контенту': 'Приділіть увагу якості контенту',
        'Максимально повністю заповнюйте анкети автомобілів': 'Максимально повністю заповнюйте анкети автомобілів',
        'Додавайте правдивий опис про технічний стан авто та, за можливості, діліться досвідом його експлуатації': 'Додавайте правдивий опис про технічний стан авто та, за можливості, діліться досвідом його експлуатації',
        'Завантажуйте фото з різних ракурсів та відео, які демонструють усі переваги авто': 'Завантажуйте фото з різних ракурсів та відео, які демонструють усі переваги авто',
        'Як потрапити в рубрику «ТОП» на Авторіа?': 'Як потрапити в рубрику «ТОП» на Авторіа?',
        'Додайте ваше оголошення в рубрику «ТОП» і воно буде розміщене на головній сторінці сайту як краща пропозиція! Оголошення на головній сторінці сайту переглядає величезна кількість покупців.': 'Додайте ваше оголошення в рубрику «ТОП» і воно буде розміщене на головній сторінці сайту як краща пропозиція! Оголошення на головній сторінці сайту переглядає величезна кількість покупців.',
        'Авто з VIN-кодом - вище в пошуку': 'Авто з VIN-кодом - вище в пошуку',
        'Дізнатися більше про рівні ТОП': 'Дізнатися більше про рівні ТОП',
      },
    },
  },
};
